import React from 'react';

function Footer() {
  const isMobile = window.innerWidth <= 768; // or any other breakpoint value you prefer

  const containerStyle = {
    marginTop: '40px',
    textAlign: 'center',
    flexDirection: isMobile ? 'column' : 'row'
  };

  const itemStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: isMobile ? '10px 0' : '0 10px'
  };

  const separatorStyle = isMobile 
    ? { display: 'none' } 
    : { margin: '0 10px' };

  return (
    <div style={containerStyle}>
     Please note that the Ethereum mainnet is the only chain currently supported.
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: containerStyle.flexDirection }}>
        {/* GitHub Info */}
        <div style={itemStyle}>
          <a href="https://github.com/tmsprks" target="_blank" rel="noopener noreferrer">
            <img 
              src="github.svg" 
              alt="GitHub Logo" 
              style={{ width: '30px', height: '30px', color: 'grey', fill: 'grey' }} 
            />
          </a>
        </div>
        
        {/* Separator */}
        <span style={separatorStyle}>|</span>

        {/* Ethereum Donation Info */}
        <div style={itemStyle}>
          <img 
              src="ethereum.svg" 
              alt="Ethereum Logo" 
              style={{ width: '30px', height: '30px', color: 'grey', fill: 'grey' }} 
          />
          <span style={{ marginLeft: '10px', marginRight: '10px', fontSize: '0.8rem' }}>Donate: 0xd6f37D25Cd428F51A16B51eb78a9B5e64f5A264d</span>
        </div>
        
        {/* Separator */}
        <span style={separatorStyle}>|</span>

        {/* Copyright Info */}
        <div style={itemStyle}>
          © {new Date().getFullYear()} @tmsprks
        </div>
        {/* Separator */}
        <span style={separatorStyle}>|</span>
        {/* Version Info */}
        <div style={itemStyle}>
         Version: 1.0.0
        </div>
      </div>
    </div>
  );
}

export default Footer;
