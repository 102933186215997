import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Container, Alert } from 'react-bootstrap';

function App() {
  const [address, setAddress] = useState('');
  const [blockNumber, setBlockNumber] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);
  const [ticker, setTicker] = useState('');

  const getBalance = async () => {
    try {
      const response = await fetch('/.netlify/functions/getBalance', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address, blockNumber, contractAddress })
      });

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }

      setBalance(data.balance);
      setTicker(data.unit);
      setError(null);
    } catch (err) {
      setError(err.message);
      setBalance(null);
    }
  };

  return (
    <Container style={{ marginTop: '50px' }}>
      <Form>
        <Form.Group className='my-3'>
          <Form.Label>Wallet Address on Ethereum</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Wallet Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='my-3'>
          <Form.Label>Token Contract Address (Leave blank for ETH)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ERC-20 Contract Address"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='my-3'>
          <Form.Label>Block Number (Leave blank for latest block)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Block Number"
            value={blockNumber}
            onChange={(e) => setBlockNumber(e.target.value)}
          />
        </Form.Group>
        <Button className="my-3" variant="primary" onClick={getBalance}>
          Check Balance
        </Button>
      </Form>
      {balance !== null && (
        <Alert variant="success" style={{ marginTop: '20px' }}>
          Balance at block {blockNumber || 'latest'}: {balance} {contractAddress ? ticker : 'ETH'}
        </Alert>
      )}
      {error && (
        <Alert variant="danger" style={{ marginTop: '20px' }}>
          {error}
        </Alert>
      )}
    </Container>
  );
}

export default App;

