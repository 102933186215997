import React from 'react';

function Header() {
  const headerStyles = {
    fontSize: '1.8rem',
    padding: '20px 0',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    marginBottom: '30px',
    fontWeight: '300',
    borderBottom: '1px solid #e1e1e1',
  };

  return <div style={headerStyles}>balanceOf</div>;
}

export default Header;
